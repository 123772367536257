import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #192619;
  padding-top: 20px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const Subcontainer = styled.div`
  flex: 1;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const Title = styled.h1`
  color: #ABCDDF;
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
`;

export const AddButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #E8FC8B;
  color: #1B2D1A;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #E8FC8B/90;
  }
`;

export const SearchContainer = styled.div`
  margin-bottom: 2rem;
  position: relative;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 0.75rem 1rem;
  padding-left: 2.5rem;
  background-color: #2A3C29;
  border: 1px solid #3A4C39;
  border-radius: 0.5rem;
  color: #ABCDDF;
  font-size: 1rem;
  transition: all 0.2s;

  &:focus {
    outline: none;
    border-color: #E8FC8B;
    background-color: #3A4C39;
  }

  &::placeholder {
    color: #ABCDDF80;
  }
`;

export const SearchIcon = styled.div`
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #ABCDDF;
`;

export const AdministratorsList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
`;

export const AdministratorCard = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: #2A3C29;
  padding: 1.5rem;
  border-radius: 0.5rem;
  color: #ABCDDF;
  transition: background-color 0.2s;

  &:hover {
    background-color: #3A4C39;
  }
`;

export const AdministratorEmail = styled.span`
  font-size: 1.1rem;
  font-weight: 500;
`;

export const NoResults = styled.div`
  text-align: center;
  color: #ABCDDF;
  padding: 2rem;
  font-size: 1.1rem;
`; 