import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavBarMain } from '..';
import { useAuth } from '../../hooks';
import { 
  PageContainer, 
  Subcontainer, 
  Header, 
  Title, 
  AdministratorsList, 
  AdministratorCard, 
  AdministratorEmail, 
  AddButton,
  SearchContainer,
  SearchInput,
  SearchIcon,
  NoResults
} from './Administrators.styled';
import { Shield, Plus, Search } from 'lucide-react';
import CreateAdministratorModal from '../CreateAdministratorModal/CreateAdministratorModal';
import ViewUsersModal from '../ViewUsersModal/ViewUsersModal';
import { toast } from 'react-toastify';

export const Administrators = ({ history }) => {
  const { t } = useTranslation();
  const { getAdministrators } = useAuth();
  const [administrators, setAdministrators] = useState([]);
  const [filteredAdministrators, setFilteredAdministrators] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUsersModal, setShowUsersModal] = useState(false);
  const [selectedAdministrator, setSelectedAdministrator] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchAdministrators = async () => {
    try {
      const response = await getAdministrators();
      const admins = Array.isArray(response) ? response : [];
      setAdministrators(admins);
      setFilteredAdministrators(admins);
    } catch (error) {
      toast.error(t('errorFetchingAdministrators'));
      setAdministrators([]);
      setFilteredAdministrators([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAdministrators();
  }, [getAdministrators, t]);

  useEffect(() => {
    const filtered = administrators.filter(admin => {
      // Get the email safely, handling different possible structures
      const email = admin?._id?.email || admin?.email || '';
      return email.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setFilteredAdministrators(filtered);
  }, [searchTerm, administrators]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const getAdminEmail = (admin) => {
    return admin?._id?.email || admin?.email || t('unknownEmail');
  };

  const handleAdministratorClick = (admin) => {
    setSelectedAdministrator(admin);
    setShowUsersModal(true);
  };

  return (
    <PageContainer>
      <NavBarMain currentPage="administrators" history={history} />
      <Subcontainer>
        <Header>
          <Title>{t('administrators')}</Title>
          <AddButton onClick={() => setShowCreateModal(true)}>
            <Plus size={20} />
            <span>{t('addAdministrator')}</span>
          </AddButton>
        </Header>

        <SearchContainer>
          <SearchIcon>
            <Search size={20} />
          </SearchIcon>
          <SearchInput
            type="text"
            placeholder={t('searchAdministrators')}
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </SearchContainer>

        {isLoading ? (
          <div>{t('loading')}</div>
        ) : filteredAdministrators.length === 0 ? (
          <NoResults>
            {searchTerm ? t('noAdministratorsFound') : t('noAdministrators')}
          </NoResults>
        ) : (
          <AdministratorsList>
            {filteredAdministrators.map((admin) => (
              <AdministratorCard 
                key={getAdminEmail(admin)}
                onClick={() => handleAdministratorClick(admin)}
                style={{ cursor: 'pointer' }}
              >
                <Shield size={24} />
                <AdministratorEmail>{getAdminEmail(admin)}</AdministratorEmail>
              </AdministratorCard>
            ))}
          </AdministratorsList>
        )}

        <CreateAdministratorModal
          show={showCreateModal}
          onClose={() => {
            setShowCreateModal(false);
            fetchAdministrators();
          }}
        />

        <ViewUsersModal
          show={showUsersModal}
          onClose={() => {
            setShowUsersModal(false);
            setSelectedAdministrator(null);
          }}
          administrator={selectedAdministrator}
        />
      </Subcontainer>
    </PageContainer>
  );
}; 